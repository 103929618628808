<template>
    <div style="height: 100%">
        <!-- 卡片视图 -->
        <el-card style="height: 100%;overflow:auto;">
            <!-- 顶部面包屑导航 -->
            <breadcrumb-nav>
                <template v-slot:firstMenu>对账管理</template>
                <template v-slot:secondMenu>未对账订单列表</template>
            </breadcrumb-nav>
            <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
                未对账订单列表
            </div>
            <div class="radioBox">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="贸易企业">
                        <el-select v-model="formInline.region" placeholder="请选择" clearable @change="onSubmit">
                            <el-option
                                    v-for="item in enterpriseList"
                                    :key="item.tardId"
                                    :label="item.tardName"
                                    :value="item.tardId"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择计划日期">
                        <el-date-picker
                                v-model="dateValue"
                                type="daterange"
                                range-separator="~"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                clearable @change="onSubmit">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="ArrangePlan()">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- table表格区域 -->
            <el-table highlight-current-row :data="goodsList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
                <el-table-column  label="序号" type="index" width="50"></el-table-column>
                <el-table-column label="订单编号" prop="orderNumber"></el-table-column>
                <el-table-column label="贸易企业名称" prop="tardName"></el-table-column>
                <el-table-column label="液源" prop="lngSourceName"></el-table-column>
                <el-table-column label="计划日期" prop="planDate" width="170px">
                    <template slot-scope="scope">
                        {{ scope.row.planDate }}
                    </template>
                </el-table-column>
                <el-table-column label="配送方式" prop="distributionType">
                    <template slot-scope="scope">
                        {{ scope.row.distributionType == 1 ? '自提' : scope.row.distributionType == 2 ? '配送' : '' }}
                    </template>
                </el-table-column>
                <el-table-column label="车辆信息">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.tractorNumber ? scope.row.tractorNumber.slice(0, -5) + '/' + scope.row.trailerNumber.slice(0, -5) : "--"
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column  label="司机信息">
                    <template slot-scope="scope">
                        <span>{{ scope.row.driverInfo ? scope.row.driverInfo.slice(0, -5) : "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="卸车量（吨）" prop="unloadWeight"></el-table-column>
                <el-table-column label="装车量（吨）" prop="shipmentWeight"></el-table-column>
                <el-table-column label="结算量（吨）" prop="weight"></el-table-column>
                <el-table-column label="结算单价（元/吨）" prop="listingPrice"></el-table-column>
                <el-table-column label="结算总金额（元）" prop="totalAmount"></el-table-column>
                <el-table-column prop="amount" label="进款金额(元)" ></el-table-column>
            </el-table>
            <div style="float: right;">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.pageNum"
                        :page-sizes="[10, 20, 30, 50]"
                        :page-size="queryInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>


    </div>
</template>

<script>
import BreadcrumbNav from "../../../common/BreadcrumbNav";

import {getFinancialListRequest, getNotReconciledRequest,exportExcel} from "network/financial";
import {getEnterpriseRequest} from "@/network/plan";

export default {
    name: "List",
    components: {
        BreadcrumbNav,
    },
    data() {
        return {
            queryInfo: {
                query: "",
                pageNum: 1,
                pageSize: 10
            },
            formInline: {
                user: '',
                region: ''
            },
            dateValue: null,
            goodsList: [],
            enterpriseList: [],
            total: 0,
        }
    },
    created() {
        this.getGoodsList();
        this.getEnterpriseList()
    },
    methods: {
        onSubmit() {
            this.getGoodsList();
        },
        getGoodsList() {
            let startTime = null
            let endTime = null
            if (this.dateValue) {
                startTime = this.dateValue[0]
                endTime = this.dateValue[1]
            }
            getNotReconciledRequest({
                ...this.queryInfo,
                tradeId: localStorage.getItem('enterpriseId'),
                stationId: this.formInline.region,
                startTime: startTime,
                endTime: endTime
            }).then(res => {
                let result = res.data;
                if (result.code !== 200) {
                    return this.alertMessage(result.msg, 'error');
                }
                this.goodsList = result.data.list;
                this.total = result.data.total;
            });
        },
        getEnterpriseList() {
            getEnterpriseRequest({
                stationId: localStorage.getItem('enterpriseId')
            }).then(res => {
                let result = res.data
                if (result.code !== 200) {

                }
                this.enterpriseList = result.data
            })
        },
        ArrangePlan() {
            let startTime = null
            let endTime = null
            if (this.dateValue) {
                startTime = this.dateValue[0]
                endTime = this.dateValue[1]
            }
            exportExcel({
                tradName: this.formInline.region,
                tradeId: localStorage.getItem('enterpriseId'),
                startTime: startTime,
                endTime: endTime
            }).then(res => {
              var content = res.headers['content-disposition'];
              var name = content && content.split(';')[1].split('filename=')[1];
              var fileName = decodeURIComponent(name)
              this.downloadCallback(res, fileName);
            });
        },
        //生成下载文件
        downloadCallback(res, fileName) {
            const content = res.data;
            const blob = new Blob([content],{
              type: "application/msexcel;charset=utf-8",
            });
            if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        },

        // 每页显示的数据条数发送变化
        handleSizeChange(newSize) {
          this.queryInfo.pageNum = 1
            this.queryInfo.pageSize = newSize;
            this.getGoodsList();
        },

        // 当前页码发生变化
        handleCurrentChange(newPage) {
            this.queryInfo.pageNum = newPage;
            this.getGoodsList();
        },

    }
}
</script>

<style scoped>

.radioBox {
    margin-top: 30px;
    margin-bottom: 15px;
}

.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}

</style>

